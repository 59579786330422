import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class HandleDateService {

    constructor() { }

    /**
     * Put some separator in date string without separator
     * * @param dateString Date in string formatted like DDMMyyyy
     * * @param separator Desired separator. 
     * Ex: '/'* @returns dd/mm/yyyy*/
    public dateStringWithSeparator(dateString: string, separator: string): string {
        const day: string = dateString.slice(0, 2);
        const month: string = dateString.slice(2, 4);
        const year: string = dateString.slice(4, undefined);
        return `${day}${separator}${month}${separator}${year}`;
    }   /**
    * The method transforms the date from a ISOString "2025-06-30T00:00:00.000Z" to a date in string with abbreviated month.
    * @param date 2025-06-30T00:00:00.000Z
    * @returns 11 APR 23
    * The navigator language parameter will identify the language defined by the user in his browser
    * and will carry out the correct translation for the abbreviation of the month.
    */
   public formatDateWithAbbreviatedMonth(date: string): string {
       if (date) {
           const newDate: Date = this.returnNoTimezoneDate(date);
           const day: string = newDate?.getDate()?.toString()?.padStart(2, "0");
           const month: string = newDate?.toLocaleString(navigator.language, { month: 'short' })?.replace('.', '')?.toUpperCase();
           const year: string = newDate?.getFullYear()?.toString();

           return `${day} ${month} ${year}`;
       }

       return '--';
   }

   /**
    * Receive a date like "2024-06-01T00:00:00.000Z"
    * Return a new Date without Timezone.
    * @param date 
    */
   public returnNoTimezoneDate(date: string): Date {
       const DATE_WITHOUT_TIMEZONE = date.split('T')[0]; // "2024-06-01T00:00:00.000Z" into "2024-06-01"
       const DATE_ARRAY_STRING: string[] = DATE_WITHOUT_TIMEZONE.split('-'); // "2024-06-01" into ["2024", "06", "01"]
       const formatedDate: Date = new Date(Number(DATE_ARRAY_STRING[0]), Number(DATE_ARRAY_STRING[1]) - 1, Number(DATE_ARRAY_STRING[2]));

       return formatedDate;
   }
}
     
     