import { VerifyUserEncrypted } from './../../models/verify.encrypted.model';
import { IUserGenericDocuments, UserLoggedModel, UserStatus } from './../../models/user.logged.model';
import { RestEndpoint } from './../../../constants/rest-endpoint.constants';
import { LoginEncrypted } from './../../models/encrypt.base';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ResetModel } from '../../models/reset.model';
import { ForgotModel } from '../../models/forgot.model';
import { LocalStorageService, SessionStorageService } from 'angular-web-storage';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { SignupEncrypt } from '../../models/signup.encrypt.base';
import { AmountConvertedResult } from '../../models/amountConvertedResult';
import { PlatformBalance } from '../../models/wallet.balance';
import { IFiatCurrency } from '../../models/IFiatCurrency';
import { LocalStorageKeys } from '../../utils/local.storage.keys';
import { UserIdentifierEnum } from '../../models/user-identifier.enum';
import { Friend } from '../../models/friends.model';
import { VerifyTransferInput, VerifyTransferOutput } from '../../models/transfer.model';
import { TransferValueModel } from '../../models/transfer-value.model';
import { ApplyStake, CancelStake } from '../../models/apply.stake';
import { LanguagesEnum } from '../../models/languages.enum';
import { TranslateService } from '@ngx-translate/core';
import { IExchangeRateHistoryResponse } from '../../models/exchange.rate.history.response';
import { IUserDocuments, PERSONAL_DOCUMENTS_FILTER } from '../../models/IUserDocuments';
import { IDecimalPlaces } from '../../models/IDecimalPlaces';
import { CapacitorHttp, HttpResponse } from '@capacitor/core';
import { IUserAddress } from '../../models/IUserAddress';
import { User } from '../../models/IUser';
import { AccessStaking } from '../../models/access.staking';
import { EscrowsUpdateRequest, IEscrowsCreationRequest } from '../../models/escrow.model';
import { TokenStatistics } from '../../models/tokens';
import { AccountCreationRequest } from '../../models/bank.account.creation.model';
import { IInvoicesCreationRequest } from '../../models/invoice.model';
import { IUserEmailResponse } from '../../models/IUserEmailResponse';
import { IUserAdditionalInformation } from '../../models/IUserAdditionalInformation';
import { IGeneralTaxConfig } from '../../models/IGeneralTaxConfig';
import { UserNotificationsSettingsModel } from '../../models/user-notifications-settings.model';
import { BrlaKycHistoryModel } from '../../models/brla-kyc-history.model';
import { IGeneralLimits } from '../../models/IGeneralLimit';

@Injectable()
export class AccountService {
    public static RSA_KEY: string;

    /**
     * Stores the user to be resetted
     */
    public userEmail: string;

    private readonly registerAccess: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public getUserLogger$: Observable<boolean> = this.registerAccess.asObservable();

    constructor(
        private readonly localStorage: LocalStorageService,
        private readonly sessionStorage: SessionStorageService,
        private readonly router: Router,
        private readonly http: HttpClient,
        private readonly translate: TranslateService
    ) {
    }

    public deleteFriends(id: string): Observable<void | Object>{
        return this.http.delete(RestEndpoint.deleteFriends + id, {})
            .pipe(
                catchError((err) => {
                    throw (err);
                })
            );
    }


    public createFriend(request: Friend): Observable<any> {
        return this.http.post<any>(RestEndpoint.createFriends, request)
            .pipe(
                map((userData: any) => {
                    return userData;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public getMyOrders(): Observable<any> {
        return this.http.get<any>(RestEndpoint.getMyOrders, {})
            .pipe(
                map((userData: any) => {
                    return userData;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public updateFriend(request: Friend): Observable<any> {

        return this.http.put<any>(RestEndpoint.updateFriend, request)
            .pipe(
                map((userData: any) => {
                    return userData;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public updateDocuments(selfie_document: string, address_document_url: string, identification_document_url: string): Observable<string> {

        return this.http.post<any>(RestEndpoint.updateDocuments, {
            selfie_document,
            address_document_url,
            identification_document_url
        })
            .pipe(
                map((userData: string) => {
                    return userData;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public uploadGenericDocuments(genericDocuments: IUserGenericDocuments[]): Observable<string> {
        return this.http.post<any>(RestEndpoint.UpdateMyGenericKYCDocuments, genericDocuments)
            .pipe(
                map((userData: string) => {
                    return userData;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public getUsers(): Observable<Array<UserLoggedModel>> {
        return this.http.get(RestEndpoint.getAllUsers, {})
            .pipe(
                map((data: Array<UserLoggedModel>) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getAllFriends(): Observable<Array<Friend>> {
        return this.http.get(RestEndpoint.friends, {})
            .pipe(
                map((data: Array<Friend>) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getMyCommunity(): Observable<Array<Friend>> {
        return this.http.get(RestEndpoint.getMyCommunity, {})
            .pipe(
                map((data: Array<Friend>) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getRsaPublicKey(): Observable<boolean | void> {
        if (AccountService.RSA_KEY) {
            return of();
        }

        return this.http.get(RestEndpoint.publicRsa, {})
            .pipe(
                map((data: any) => {
                    if (!data.value) {
                        throw new Error('Problems to retrieve RSA Key.');
                    }
                    AccountService.RSA_KEY = data.value;
                    return of();
                }),
                map(() => true),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    /**
     * Get name and password inserted on login and compare with data. If it's match, return true to do login.
     * @param name
     * @param password
     */
    public async loginUser(name: string, password: string, code?: string): Promise<void> {

        const options = {
            url: RestEndpoint.authenticateUser,
            headers: {'Content-Type': 'application/json'},
            data: new LoginEncrypted(name, password, name, code, AccountService.RSA_KEY),
        };
        const response: HttpResponse = await CapacitorHttp.post(options);

        if (response.status === 200) {
            const userData = response.data;
            if (!userData) {
                throw new HttpErrorResponse({ status: 405, statusText: 'Impossible to retrieve user data.' });
            }
            userData.name = `${userData.firstName} ${userData.lastName}`;
            this.localStorage.set(LocalStorageKeys.USER_LOGGED_KEY, userData);
            this.registerAccess.next(true);
            return Promise.resolve(response.data);
        } else {
            return Promise.reject(response.data);
        }
    }

    public resendCode(email: string): Observable<string> {
        return this.http.post<any>(RestEndpoint.resendConfirmationCode, {email})
            .pipe(
                catchError((err) => {
                    throw err;
                })
            );
    }

    public sendMFACode(): Observable<string> {
        return this.http.post<any>(RestEndpoint.sendMFACode, {})
            .pipe(
                catchError((err) => {
                    throw err;
                })
            );
    }

    public loadDecimalsPlaces(): Observable<IDecimalPlaces> {
        return this.http.get(RestEndpoint.getDecimalPlaces, {})
            .pipe(
                map((data: IDecimalPlaces) => {
                    return data;
                }),
                catchError(() => {
                    throw new Error('Not possible to get the decimals')
                })
            );
    }

    public createUser(
        email: string,
        password: string,
        firstName: string,
        lastName: string,
        identifier: string,
        indicationToken: string,
        documentType: UserIdentifierEnum | typeof UserIdentifierEnum,
        documentToken: string,
        token_id: string,
        account_type?: string,
        externalSourceIndication?: string,
        nft_token?: string,
        accountCreationRequest?: AccountCreationRequest,
        phone?: string,
        username?: string,
        dateOfBirth?: string,
        openingDate?: string,
        identityCompanyManager?: string,
        isPhoneVerified?: boolean
        ): Observable<any> {
        return this.http.post<any>(RestEndpoint.createUser, new SignupEncrypt(
            email,
            password,
            email,
            firstName,
            lastName,
            identifier,
            indicationToken,
            AccountService.RSA_KEY,
            documentType,
            documentToken,
            token_id,
            accountCreationRequest,
            account_type,
            externalSourceIndication,
            nft_token,
            phone,
            username,
            dateOfBirth,
            openingDate,
            identityCompanyManager,
            isPhoneVerified
            ))
            .pipe(
                catchError((err) => {
                    throw err;
                })
            );
    }

    /**
     * Check email on Forgot Password page. If don't match, don't let go to Reset Password Page
     * @param user
     */
    public sendCode(user: ForgotModel): Observable<void> {
        this.userEmail = user.email;
        return this.http.post<void>(RestEndpoint.lostpassword, user)
            .pipe(
                catchError((err) => {
                    throw err;
                })
            );
    }

    /**
     * Destroy the session
     */
    public destroySession(): Observable<void> {
        this.localStorage.set(LocalStorageKeys.USER_LOGGED_KEY, undefined);
        this.localStorage.remove(LocalStorageKeys.BANK_ACCOUNT_ORDERED);
        this.sessionStorage.clear();
        this.registerAccess.next(false);
        return this.http.delete<void>(RestEndpoint.destroySession);
    }

    /**
     * Compare the email, verification code and new password to reset the password on Reset Password Page
     * @param reset
     */
    public resetPassword(reset: ResetModel): Observable<void> {
        return this.http.post<void>(RestEndpoint.changelostpassword, new VerifyUserEncrypted(reset, AccountService.RSA_KEY))
            .pipe(
                catchError((err) => {
                    throw err;
                })
            );
    }

    public getLoggedUserDetails(): Observable<UserLoggedModel> {
        const user = this.localStorage.get(LocalStorageKeys.USER_LOGGED_KEY);
        if (user) {
            return of(user);
        } else {
            return of(undefined);
        }
    }

    public getBTCWallet(): Observable<any> {
        return this.http.get<any>(RestEndpoint.getBTCWallet
            ).pipe(
            catchError((err) => { throw err; })
        );
    }

    public logout(): void {
        this.destroySession().subscribe();
    }

    /**
     * Check if the user is authenticated
     */
    public isAuthenticated(menu?: boolean, redirectToLogin: boolean = true): Observable<UserLoggedModel> {
        return this.internalIsAuthenticated(menu, redirectToLogin);
    }

    private internalIsAuthenticated(menu?: boolean, redirectToLogin: boolean = true): Observable<any> {
        return this.http.post<boolean>(RestEndpoint.isAuthenticated, {})
            .pipe(
                map((authenticated: any) => {
                    if (!authenticated || !authenticated.isAuthenticated) {
                        if (!menu) {
                            this.destroySession().subscribe();
                            if (redirectToLogin) {
                                void this.router.navigate(['/account/login']);
                            }
                        }
                        return;
                    }
                    if (authenticated.user) {
                        authenticated.user.name = `${authenticated.user.firstName} ${authenticated.user.lastName}`;

                        this.localStorage.set(LocalStorageKeys.USER_LOGGED_KEY, authenticated.user);
                    }
                    return of(this.localStorage.get(LocalStorageKeys.USER_LOGGED_KEY));
                }),
                catchError((err) => {
                    this.destroySession().subscribe();
                    if (redirectToLogin) {
                        void this.router.navigate(['/account/login']);
                    }
                    throw err;
                })
            );
    }

    public changeBu(buId: string): Observable<any> {
        return this.http.post<any>(RestEndpoint.changeBu, { buId })
            .pipe(
                map((userData: any) => {
                    if (!userData) {
                        throw new HttpErrorResponse({ status: 405, statusText: 'Impossible to change BU.' });
                    }
                }),
                catchError((err) => {
                    throw err;

                })
            );
    }

    //verificar
    public sendNotification(data: {
        name?: string,
        contact: string,
        observation: string,
        emailFrom: string,
        emailTo: string,
        product: string,
        period: string,
        storeId?: string,
        subject: string
    }): Observable<any> {
        return this.http.post<any>(RestEndpoint.sendContactEmail, data)
            .pipe(
                map((userData: any) => {
                    if (!userData)
                    {
                        throw new HttpErrorResponse({status: 405, statusText: 'Impossible to send notification.'});
                    }
                }),
                catchError((err) => {
                    throw err;

                })
            );
    }

    /**
     * Return if the user is authenticated, but don't destroy the session if he isn't
     */
    public verifyAuthentication(): Observable<boolean> {
        return this.http.post<boolean>(RestEndpoint.isAuthenticated, {})
            .pipe(
                map((authenticated: any) => {
                    if (!authenticated || !authenticated.isAuthenticated) {
                        return false;
                    } else {
                        return true;
                    }
                })
            );
    }

    public verifyDocument(cpf: string, cnpj: string): Observable<any> {
        return this.http.get<any>(RestEndpoint.verifyDocument, {
            params: {
                'cpf': cpf,
                'cnpj': cnpj
            }
        }).pipe(
            catchError((err) => { throw err; })
        );
    }

    public changeNewsLetter(receivesNewletter: boolean, email: string): Observable<boolean> {
        return this.http.post<boolean>(RestEndpoint.changeNewsLetter, { receivesNewletter, email })
            .pipe(
                map((res: any) => {
                    return res;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getUserAddress(filterKycAddress?: boolean): Observable<IUserAddress> {
        return this.http.get(RestEndpoint.getUserAddress, {})
            .pipe(
                map((data: Array<IUserAddress>) => {
                    //For retro-compatibility, in case didn't find any kyc address, just keep retrieving the first one found.
                    let address: IUserAddress = data[0];

                    if (filterKycAddress) {
                        const kycAddress: IUserAddress = data.filter(item => item.has_kyc)[0];
                        if (kycAddress){
                            address = kycAddress;
                        }
                    }
                    return address;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getUserAdditionalinformation(filterKycAddress?: boolean): Observable<any[]> {
        return this.http.get(RestEndpoint.getAllUserAdditional, {})
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public createAddress(data: IUserAddress): Observable<any> {
        return this.http.post<any>(RestEndpoint.createUserAddress, data)
        .pipe(
            catchError((err) => {
                throw err;
            })
        );
    }

    public createAdditionalInformation(data: IUserAdditionalInformation): Observable<any> {
        return this.http.post<any>(RestEndpoint.createUserAdditional, data)
        .pipe(
            catchError((err) => {
                throw err;
            })
        );
    }


    public updateAddress(data: IUserAddress): Observable<void> {
        return this.http.put<any>(RestEndpoint.updateUserAddress, data)
        .pipe(
            catchError((err) => {
                throw err;
            })
        );
    }

    public quotations(currency: string): Observable<AmountConvertedResult> {
        let unitOfMoney = currency;
        return this.http.get(RestEndpoint.quotations, { params: { currency, unitOfMoney } })
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(() => {
                    throw new Error('Not possible to get the quotation')
                })
            );
    }

    public getAllHistory(currency: string): Observable<any> {
        let unitOfMoney = currency;
        return this.http.get(RestEndpoint.getAllHistory, { params: { currency, unitOfMoney } })
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(() => {
                    throw new Error('Not possible to get the quotation')
                })
            );
    }

    public getUserByWallet(wallet: string): Observable<User> {
        return this.http.get(RestEndpoint.getUserByWallet, { params: { wallet } })
            .pipe(
                map((data: User) => {
                    return data;
                }),
                catchError(() => {
                    throw new Error('Not possible to get the user wallet')
                })
            );
    }

    public getUserByEmailWallet(email: string): Observable<IUserEmailResponse> {
        email = email?.toLocaleLowerCase()?.trim();
        return this.http.get(RestEndpoint.getUserByEmailWallet, { params: { email } })
            .pipe(
                map((data: IUserEmailResponse) => {
                    return data;
                }),
                catchError(() => {
                    throw new Error('Not possible to get the user wallet')
                })
            );
    }

    public getUserListInPayroll(): Observable<any> {
        return this.http.get(RestEndpoint.getUserListPayroll)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(() => {
                    throw new Error('Not possible to get the user wallet')
                })
            );
    }

    public getBRLAKYCStatus(): Observable<BrlaKycHistoryModel> {
        return this.http.get(RestEndpoint.getBRLAKYCStatus)
            .pipe(
                map((data: BrlaKycHistoryModel) => {
                    return data;
                }),
                catchError(() => {
                    throw new Error('Not possible to get the user wallet')
                })
            );
    }


    public sendBRLAFirstStatus(obj: {
        "cpf": string,
        "birthDate": string,
        "fullName": string
    }): Observable<any> {
        return this.http.post(RestEndpoint.createPFLevel1, obj)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(() => {
                    throw new Error('Not possible to get the user wallet')
                })
            );
    }


    public sendBRLAFirstStatusPJ(obj: {
        "cnpj": string,
        "startDate": string,
        "companyName": string
    }): Observable<any> {
        return this.http.post(RestEndpoint.createPJLevel1, obj)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(() => {
                    throw new Error('Not possible to get the user wallet')
                })
            );
    }

    public sendBRLASecondStatusPJ(obj: {
        "cnpj": string,
        "cpf": string,
        "birthDate": string,
        "fullName": string
    }): Observable<any> {
        return this.http.post(RestEndpoint.createPJLevel2, obj)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(() => {
                    throw new Error('Not possible to get the user wallet')
                })
            );
    }

    public sendBRLASecondStatus(obj: {
        "cpf": string,
        "documentType": string
    }): Observable<{
        selfieUploadUrl: string,
        CNHUploadUrl: string
    }> {
        return this.http.post(RestEndpoint.createPFLevel2, obj)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(() => {
                    throw new Error('Not possible to get the user wallet')
                })
            );
    }

    public sendKYCDocument(file: any, url: string): Observable<{
        selfieUploadUrl: string,
        CNHUploadUrl: string
    }> {
        return this.http.put(RestEndpoint.sendDocBRLA, {document_url: file, apiURL: url})
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(() => {
                    throw new Error('Not possible to get the user wallet')
                })
            );
    }


    public createUserInPayroll(userIdTo: string, amountRequested: number, creationDate: Date, expirationDate: Date, description: string, unitOfMoneyRequested: string = 'BRL'): Observable<any> {
        return this.http.post(RestEndpoint.createUserPayroll, { userIdTo, unitOfMoneyRequested, amountRequested, creationDate, expirationDate, description, enabled: true })
            .pipe(
                map((data: any) => {
                    if(data) {
                        return data;
                    }
                    return [];
                }),
                catchError(() => {
                    throw new Error('Not possible to get the quotation')
                })
            );
    }

    public updateUserInPayroll(id: string, userIdTo: string, amountRequested: number, creationDate: Date, expirationDate: Date, description: string, unitOfMoneyRequested: string = 'BRL'): Observable<any> {
        return this.http.post(RestEndpoint.updateUserPayroll, { id, userIdTo, unitOfMoneyRequested, amountRequested, creationDate, expirationDate, description })
            .pipe(
                map((data: any) => {
                    if(data) {
                        return data;
                    }
                    return [];
                }),
                catchError(() => {
                    throw new Error('Not possible to get the quotation')
                })
            );
    }

    public deleteUserInPayroll(id: string) {
        return this.http.post(RestEndpoint.deleteUserPayroll, { recurrencyId: id })
            .pipe(
                catchError((err) => {
                    throw (err);
                })
            );
    }


    public quotationHistory(currency: string, limit: number = 50): Observable<IExchangeRateHistoryResponse[]> {
        let unitOfMoney = currency;
        return this.http.get(RestEndpoint.quotationsHistory, { params: { currency, unitOfMoney, limit } })
            .pipe(
                map((data: IExchangeRateHistoryResponse[]) => {
                    return data;
                }),
                catchError(() => {
                    throw new Error('Not possible to get the quotation')
                })
            );
    }


    public fasQuote(amount: number, coin: string, side: string): Observable<any> {
        return this.http.post(RestEndpoint.fastPrice, { amount, coin, side })
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(() => {
                    throw new Error('Not possible to get the quotation')
                })
            );
    }

    public performSwap(amount: number, expectedAmount: string, unitOfMoney: string, side:string,pix_key: string ): Observable<any> {
        return this.http.post(RestEndpoint.performSwap, { amount, expectedAmount, unitOfMoney, side, pix_key })
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(() => {
                    throw new Error('Not possible to get the quotation')
                })
            );
    }

    public allQuotations(): Observable<AmountConvertedResult[]> {

        return this.http.get(RestEndpoint.allQuotations, {})
            .pipe(
                map((data: AmountConvertedResult[]) => {
                    if(data) {
                        return data;
                    }
                    return [];
                }),
                catchError(() => {
                    throw new Error('Not possible to get the quotation')
                })
            );
    }

    public getDataForExchange(): Observable<TokenStatistics[]> {

        return this.http.get(RestEndpoint.getDataForExchange, {})
            .pipe(
                map((data: TokenStatistics[]) => {
                    if(data) {
                        return data;
                    }
                    return [];
                }),
                catchError(() => {
                    throw new Error('Not possible to get the quotation')
                })
            );
    }
    
    public createOrder(userId: string, orderType: any, quantity: number, price: number, operation: any, side: any, unitOfMoney: string): Observable<any> {
        const currentDate = new Date();
        currentDate.setHours(new Date().getHours() + 3);
        return this.http.post(RestEndpoint.addOrder, { userId, orderType, quantity, price, operation, side, unitOfMoney, when: currentDate })
            .pipe(
                map((data: any) => {
                    if(data) {
                        return data;
                    }
                    return [];
                }),
                catchError(() => {
                    throw new Error('Not possible to get the quotation')
                })
            );

    }

    public cancelOrder(orderId: string, unitOfMoney: string): Observable<any> {
        return this.http.post(RestEndpoint.cancelOrder, { orderId, unitOfMoney })
            .pipe(
                map((data: any) => {
                    if(data) {
                        return data;
                    }
                    return [];
                }),
                catchError(() => {
                    throw new Error('Not possible to get the quotation')
                })
            );

    }

    public verifyTransaction(
        verifyData: VerifyTransferInput,
    ): Observable<VerifyTransferOutput> {
        return this.http.post(RestEndpoint.verify, verifyData)
            .pipe(
                map((data: VerifyTransferOutput) => {
                    return data;
                }),
                catchError(() => {
                    throw new Error('Not possible to get the balance')
                })
            );
    }

    public sendEscrow(request: IEscrowsCreationRequest): Observable<any> {
        return this.http.post(RestEndpoint.createEscrow, request)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(() => {
                    throw new Error('Not possible to get the balance')
                })
            );
    }

    public sendInvoice(request: IInvoicesCreationRequest): Observable<any> {
        return this.http.post(RestEndpoint.createInvoice, request)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(() => {
                    throw new Error('Not possible to get the balance')
                })
            );
    }

    public updateEscrow(request: EscrowsUpdateRequest): Observable<any> {
        return this.http.post(RestEndpoint.updateEscrow, request)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(() => {
                    throw new Error('Not possible to get the balance')
                })
            );
    }

    public updateInvoice(request: EscrowsUpdateRequest): Observable<any> {
        return this.http.post(RestEndpoint.updateInvoice, request)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(() => {
                    throw new Error('Not possible to get the balance')
                })
            );
    }

    public transfer(
        transferValue: TransferValueModel,
    ): Observable<any> {
        transferValue.unitOfMoney = this.localStorage.get(LocalStorageKeys.UNIT_OF_MONEY);

        return this.http.post(RestEndpoint.transfer, transferValue)
            .pipe(
                map((data: VerifyTransferOutput) => {
                    return data;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }


    public checkQuote(
        amount: number,
    ): Observable<any> {
        return this.http.post(RestEndpoint.quote, {
            amount
        })
            .pipe(
                map((data: VerifyTransferOutput) => {
                    return data;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public ConvertUSDToPixBRLA(
        dt: any,
    ): Observable<any> {
        return this.http.post(RestEndpoint.ConvertUSDToPixBRLA, dt)
            .pipe(
                map((data: VerifyTransferOutput) => {
                    return data;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public createUserStake(
        applyStake: ApplyStake,
    ): Observable<any> {

        return this.http.post(RestEndpoint.createUserStake, applyStake)
            .pipe(
                map((data: VerifyTransferOutput) => {
                    return data;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public createSignRequest(
        applyStake: any,
    ): Observable<any> {

        return this.http.post(RestEndpoint.createSignRequest, applyStake)
            .pipe(
                map((data: VerifyTransferOutput) => {
                    return data;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public cancelUserStake(
        cancelStake: CancelStake,
    ): Observable<{value: boolean}> {

        return this.http.post(RestEndpoint.cancelUserStake, cancelStake)
            .pipe(
                map((data: {value: boolean}) => {
                    return data;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public startMFAProcess(name: string, password: string, code: string, configureForInternal: boolean  ): Observable<any> {
        return this.http.post<any>(RestEndpoint.startMFAProcess, new LoginEncrypted(name, password, name, code, AccountService.RSA_KEY, configureForInternal))
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((error) => {
                    throw (error.error.message);
                    // throw new Error ('Impossible to retrieve user data.')
                })
            );
    }

    public finalizeMFAProcess(name: string, password: string, code: string, configureForInternal: boolean ): Observable<any> {
        return this.http.post<any>(RestEndpoint.finalizeMFAProcess, new LoginEncrypted(name, password, name, code, AccountService.RSA_KEY, configureForInternal))
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((error) => {
                    throw (error.error.message);
                    // throw new Error ('Impossible to retrieve user data.')
                })
            );
    }

    public verifyMFAStatus(): Observable<{
        status: boolean,
        type: string
    }> {
        return this.http.get(RestEndpoint.verifyMFAStatus, {})
            .pipe(
                map((data: any) => {
                    return {
                        status: data?.value,
                        type: data?.type
                    };
                }),
                catchError(() => {
                    throw new Error('Not possible to get the balance')
                })
            );
    }

    public balance(unit: string): Observable<PlatformBalance[]> {
        let unitOfMoney = unit;
        return this.http.get(RestEndpoint.balance, {
            params: {
                unitOfMoney
            }
        })
            .pipe(
                map((data: PlatformBalance[]) => {
                    return data;
                }),
                catchError(() => {
                    throw new Error('Not possible to get the balance')
                })
            );
    }

    public allBalances(): Observable<PlatformBalance[]> {
        return this.http.get(RestEndpoint.balance, {
        })
            .pipe(
                map((data: PlatformBalance[]) => {
                    return data;
                }),
                catchError(() => {
                    throw new Error('Not possible to get the balance')
                })
            );
    }

    public getMinAmountToTransfer(): Observable<number> {
        return this.http.get(RestEndpoint.getMinAmountToTransfer)
            .pipe(
                map((data: any) => {
                    return data?.value;
                }),
                catchError(() => {
                    throw new Error('Not possible to get the balance')
                })
            );
    }

    public loadFiatCurrency(): Observable<IFiatCurrency> {
        return this.http.get(RestEndpoint.getFiatCurrency, {})
            .pipe(
                map((data: IFiatCurrency) => {
                    return data;
                }),
                catchError(() => {
                    throw new Error('Not possible to get the fiat currency')
                })
            );
    }

    public cleanFiatCurrency(): void {
        this.localStorage.remove(LocalStorageKeys.FIAT_CURRENCY_KEY);
    }

    public getFiatCurrency(): IFiatCurrency {
        return {
            currency: 'BRL',
            symbol: 'R$'
        };
    }

    public getCryptoCurrencies(): Observable<any> {
        return this.http.get(RestEndpoint.getCryptoCurrencies, {})
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getMyKYCDocuments(filter?: PERSONAL_DOCUMENTS_FILTER): Observable<Array<IUserDocuments>> {
        return this.http.get(RestEndpoint.getMyDocuments, { params: { filter }})
            .pipe(
                map((data: Array<IUserDocuments>) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }
    
    public getMyKYCDocumentsStatus(): Observable<UserStatus> {
        return this.http.get(RestEndpoint.getMyKYCDocumentsStatus, {})
            .pipe(
                map((data: {value: UserStatus}) => {
                    return data.value;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public checkPermission(moduleName: string): Observable<boolean> {
        return this.http.get(RestEndpoint.checkPermission, {
            params: {
                moduleName: moduleName
            }
        })
            .pipe(
                map((data: any) => {
                    return data?.value;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getPaymentStatus(orderId: string): Observable<any> {
        return this.http.get(RestEndpoint.checkPixPaymentStatus + orderId)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }


    public getClearLedgerPaymentStatus(orderId: string): Observable<any> {
        return this.http.get(RestEndpoint.CheckClearLedgerPaymentStatus + orderId)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getCelcoinIntegrationPaymentStatus(orderId: string): Observable<any> {
        return this.http.get(RestEndpoint.CheckCelcoinIntegrationPaymentStatus + orderId)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public checkDocumentStatus(document_sign_key: string): Observable<any> {
        return this.http.post(RestEndpoint.checkDocumentStatus, {document_sign_key: document_sign_key})
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getUserLanguage(): LanguagesEnum {
        const language: string = this.sessionStorage.get(LocalStorageKeys.LANGUAGE_LIST);
        if (!language) {
            const browserLang: string = this.translate.getBrowserLang();
            if (browserLang.match(/pt/)) {
                return LanguagesEnum.PORTUGUESE;
            } else {
                return LanguagesEnum.ENGLISH;
            }
        }
        return language as LanguagesEnum;
    }

    public updateBasicInfos(
        fullName: string,
        nickname: string,
        phone: string,
        externalSourceIndication?: string,
        externalSourceId?: string,
        selfieImage?: string,
        dateOfBirth?: Date,
        openingDate?: Date,
        identityCompanyManager?: string
    ): Observable<void> {
        return this.http.post<any>(RestEndpoint.updateUserBasicInfos, {
            fullName,
            nickname,
            phone,
            externalSourceIndication,
            externalSourceId,
            selfieImage,
            dateOfBirth,
            openingDate,
            identityCompanyManager
        })
            .pipe(
                map((data: { id: string }) => {
                    let userData: UserLoggedModel = this.localStorage.get(LocalStorageKeys.USER_LOGGED_KEY);
                    userData.nickname = nickname;
                    userData.phone = phone;
                    this.localStorage.set(LocalStorageKeys.USER_LOGGED_KEY, userData);
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public getUserStakingPermissions(): Observable<AccessStaking[]> {
        return this.http.get(RestEndpoint.getUserStakingPermissions)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw(err);
                })
        );
    }

    public getExchangeTaxes(): Observable<any> {
        return this.http.get<any>(RestEndpoint.GetPlatformTaxes).pipe(
            catchError((err) => { throw err; })
        );
    }

    public calculateUserTaxes(): Observable<any> {
        return this.http.get<any>(RestEndpoint.CalculateUserTaxes).pipe(
            catchError((err) => { throw err; })
        );
    }

    public readLocation(): Observable<Array<IUserAddress>> {
        return this.http.get(RestEndpoint.readLocation, {
        })
            .pipe(
                map((data: Array<IUserAddress>) => {
                    return data;
                }),
                catchError((err) => {
                    throw(err);
                })
        );
    }

    public validateUsername(externalSourceId: string): Observable<any> {
        return this.http.get<any>(RestEndpoint.validateUsername, {
            params: {
                'externalSourceId': externalSourceId
            }
        }).pipe(
            catchError((err) => { throw err; })
        );
    }

    public getGeneralTax(): Observable<IGeneralTaxConfig> {
        return this.http.get<IGeneralTaxConfig>(RestEndpoint.getGeneralTax).pipe(
            map((data: IGeneralTaxConfig) => {
                return data;
            }),
            catchError((err) => {
                throw (err);
            })
        );
    }

    public getUserNotificationsSettings(): Observable<UserNotificationsSettingsModel> {
        return this.http.get<UserNotificationsSettingsModel>(RestEndpoint.getUserNotificationsSettings).pipe(
            map((data: UserNotificationsSettingsModel) => {
                return data;
            }),
            catchError((err) => {
                throw (err);
            })
        );
    }

    public getUserLimits(): Observable<IGeneralLimits> {
        return this.http.get<IGeneralLimits>(RestEndpoint.getUserLimits).pipe(
            map((data: IGeneralLimits) => {
                return data;
            }),
            catchError((err) => {
                throw (err);
            })
        );
    }

    public updateUserNotificationsSettings(notifications: UserNotificationsSettingsModel): Observable<UserNotificationsSettingsModel> {
        return this.http.put<UserNotificationsSettingsModel>(RestEndpoint.updateUserNotificationsSettings, notifications)
            .pipe(
                map((data: UserNotificationsSettingsModel) => {
                    return data;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public sendMobileToken(phone: string, token: string, method: string): Observable<any> {
        return this.http.post<any>(RestEndpoint.sendMobileToken, { phone, token, method })
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public getMobileValidationMethods(): Observable<{value: string[]}> {
        return this.http.get<{value: string[]}>(RestEndpoint.getMobileValidationMethods).pipe(
            map((data: {value: string[]}) => {
                return data;
            }),
            catchError((err) => {
                throw (err);
            })
        );
    }
}