import { map, catchError } from 'rxjs/operators';
import { RestEndpoint } from '../../../constants/rest-endpoint.constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { StakingBalanceHistory } from '../../models/staking.balance.history';
import { StakingConfig } from '../../models/staking.config';
import { UserTransactionHistory } from '../../models/user.transaction.history';
import { TokensPairs } from '../../models/tokens.pairs';
import { ISwapCriptoToken } from '../../models/swap.model';
import { IOrderEvent } from '../../models/order.event';
import { DistributionRequestsModel } from '../../models/distribution.requests.model';

@Injectable()
export class FinancialService {

    constructor(
        private readonly http: HttpClient
    ) {
    }

    public getJournal(unitOfMoney: string): Observable<any> {

        return this.http.get(RestEndpoint.journal, {
            params: {
                unitOfMoney: unitOfMoney
            }
        })
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                }));
    }

    public getUserStakes(unitOfMoney: string): Observable<StakingBalanceHistory[]> {
        return this.http.get(RestEndpoint.getUserStakes, {
            params: {
                unitOfMoney: unitOfMoney
            }
        })
            .pipe(
                map((data: StakingBalanceHistory[]) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                }));
    }

    public getStakingConfig(unitOfMoney: string): Observable<StakingConfig[]> {
        return this.http.get(RestEndpoint.getStakingConfig, {
            params: {
                unitOfMoney: unitOfMoney
            }
        })
            .pipe(
                map((data: StakingConfig[]) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                }));
    }

    public getAllUsersTransactionsReport(startDate: Date, endDate: Date): Observable<string> {
        if (!startDate || !endDate) {
            throw new Error('"startDate" and "endDate" properties are mandatory');
        }

        return this.http.get(RestEndpoint.getMyTransactionsReport, { params: { 'startDate': startDate.getTime(), 'endDate': endDate.getTime() } })
            .pipe(
                map((data: { value: string }) => {
                    if (!data.value) {
                        throw new Error('Problems to retrieve the user transactions report.');
                    }
                    return data.value;
                }),
                catchError((err) => {
                    throw (err);
                }
                )
            );
    }

    public getMyTransactionHistory(
        unitOfMoney: string,
        transactionFlow: string,
        periodRange: string,
        transactionId: string,
        limit: number,
        offset: number): Observable<Array<UserTransactionHistory>> {
        let params = new HttpParams();
        if (unitOfMoney) params = params.set('unitOfMoney', unitOfMoney);
        if (transactionFlow) params = params.set('transactionFlow', transactionFlow);
        if (periodRange) params = params.set('periodRange', periodRange);
        if (transactionId) params = params.set('transactionId', transactionId);
        params = params.set('limit', limit);
        params = params.set('offset', offset);

        return this.http.get(RestEndpoint.getMyTransactionHistory, { params })
            .pipe(
                map((data: Array<UserTransactionHistory>) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                }));
    }

    public getAllowedStartDateTransactionsReport(): Observable<string> {

        return this.http.get(RestEndpoint.getAllowedStartDateTransactionsReport)
            .pipe(
                map((data: { value: string }) => {
                    return data.value;
                }),
                catchError((err) => {
                    throw (err);
                }
                )
            );
    }

    public getAllSwapPairs(): Observable<TokensPairs[]> {
        return this.http.get(RestEndpoint.getAllSwapPairs)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getAllSwapCriptoToken(): Observable<{ tokensPairs: TokensPairs[], swapCriptos: ISwapCriptoToken[] }> {
        return this.http.get(RestEndpoint.getAllSwapCriptoToken)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public checkoutSwap(order: IOrderEvent): Observable<IOrderEvent> {
        return this.http.post<IOrderEvent>(RestEndpoint.performSwap, order);
    }

    public createCheckout(order: IOrderEvent): Observable<IOrderEvent> {
        return this.http.post<IOrderEvent>(
            RestEndpoint.createCheckout, order
        )
            .pipe(
                map((result) => {
                    return result;
                }),
                catchError((err) => {
                    throw ('Failed');
                })
            );
    }

    public realizeExternalDeposit(depositData: any): Observable<any> {
         return this.http.post<any>(RestEndpoint.externalDeposit, depositData)
             .pipe(
                map((result) => {
                     return result?.data;
                 }),
                 catchError((err) => {
                     throw ('Failed');
                 })
             );
    }

    public getLimitTransaction(): Observable<number> {
        return this.http.get(RestEndpoint.getLimitTransaction)
        .pipe(
            map((data: {value: number}) => {
                return data?.value;
            }),
            catchError((err) => {
                throw (err);
            })
        );
    }

    public getHashTransaction(transactionId: string, userId: string): Observable<string> {
        return this.http.get<any>(RestEndpoint.getHashTransaction, {
            params: {
                'transactionId': transactionId,
                'userId': userId
            }
        }).pipe(
            map((data: {value: string}) => {
                return data?.value;
            }),
            catchError((err) => {
                throw (err);
            })
        );
    }

    public getDistributionsByAssetId(assetId: string): Observable<DistributionRequestsModel[]> {
        return this.http.get(RestEndpoint.getDistributionsByAssetId, { params: { assetId: assetId } })
            .pipe(
                map((data: DistributionRequestsModel[]) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }
}