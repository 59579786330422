import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-web-storage';
import { CurrencyMaskConfig } from 'ngx-currency';
import { EInvestmentFrequency, EInvestorProfile, DefaultListValues, InvestorProfile, EKnowledgeInvestment, EInvestmentRisk } from 'src/app/shared/models/investments.model';
import { UserLoggedModel } from 'src/app/shared/models/user.logged.model';
import { AccountService } from 'src/app/shared/services/account/account.services';
import { InvestmentsService } from 'src/app/shared/services/investments/investments.service';
import { TranslationConstants } from 'src/app/shared/services/util/translation.constants';
import { ValueConverterService } from 'src/app/shared/services/util/value-converter.service';
import { LocalStorageKeys } from 'src/app/shared/utils/local.storage.keys';

@Component({
    selector: 'app-investor-profile-quiz-modal',
    templateUrl: './investor-profile-quiz-modal.component.html',
    styleUrls: ['./investor-profile-quiz-modal.component.scss']
})
export class InvestorProfileQuizModalComponent implements OnInit, AfterViewInit {

    @ViewChild('stepper', { static: false }) public stepper: MatHorizontalStepper;
    public loading: boolean = false;
    public saveQuizLoading: boolean = false;
    public currentStepperIndex: number = 0;
    public progressBarWidth: number = 0;
    public allKnowledgeLevel: Array<DefaultListValues> = [
        {
            text: this.translationConstants.translate('investments.quiz.knowledgeBasic'),
            code: EInvestorProfile.BASIC
        },
        {
            text: this.translationConstants.translate('investments.quiz.knowledgeModerate'),
            code: EInvestorProfile.MODERATE
        },
        {
            text: this.translationConstants.translate('investments.quiz.knowledgeAdvanced'),
            code: EInvestorProfile.ADVANCED
        }
    ];
    public maskOptions: CurrencyMaskConfig = undefined;
    public allTrainingProfessionalExperienceOptions: Array<DefaultListValues> = [
        {
            text: this.translationConstants.translate('investments.quiz.experienceTraining'),
            code: EKnowledgeInvestment.TRAINING
        },
        {
            text: this.translationConstants.translate('investments.quiz.experienceProfessionalExperince'),
            code: EKnowledgeInvestment.PROFESSIONAL_EXPERIENCE
        },
        {
            text: this.translationConstants.translate('investments.quiz.experienceTrainingProfessionalExperience'),
            code: EKnowledgeInvestment.TRAINING_PROFESSIONAL_EXPERIENCE
        },
        {
            text: this.translationConstants.translate('investments.quiz.experienceNone'),
            code: EKnowledgeInvestment.NONE
        }
    ];
    public allInvestmentFrequencyOptions: Array<DefaultListValues> = [
        {
            text: this.translationConstants.translate('investments.quiz.frequencyAlways'),
            code: EInvestmentFrequency.ALWAYS
        },
        {
            text: this.translationConstants.translate('investments.quiz.frequencySometimes'),
            code: EInvestmentFrequency.SOMETIMES
        },
        {
            text: this.translationConstants.translate('investments.quiz.frequencyNever'),
            code: EInvestmentFrequency.NEVER
        }
    ];
    public allInvestmentRiskOptions: Array<DefaultListValues> = [
        {
            text: this.translationConstants.translate('investments.quiz.riskLittle'),
            code: EInvestmentRisk.LITTLE
        },
        {
            text: this.translationConstants.translate('investments.quiz.riskAverage'),
            code: EInvestmentRisk.AVERAGE
        },
        {
            text: this.translationConstants.translate('investments.quiz.riskVery'),
            code: EInvestmentRisk.VERY
        }
    ];
    public investorProfileData: InvestorProfile = new InvestorProfile();

    public allInvestmentsDate: Array<DefaultListValues> = [ //TODO check
        {
            text: this.translationConstants.translate('investments.quiz.dateOneYear'),
            date: this.getYears(new Date(), 1),
        },
        {
            text: this.translationConstants.translate('investments.quiz.dateOneAndThreeYears'),
            date: {
                startDate: this.getYears(new Date(), 1),
                endDate: this.getYears(new Date(), 3)
            }
        },
        {
            text: this.translationConstants.translate('investments.quiz.dateThreeYearsMore'),
            date: this.getYears(new Date(), 3)
        }
    ];
    public loggedUser: UserLoggedModel = undefined; //TODO

    constructor(
        private readonly dialogRef: MatDialogRef<InvestorProfileQuizModalComponent>,
        private readonly valueConverterService: ValueConverterService,
        private readonly router: Router,
        private readonly accountService: AccountService,
        private readonly localStorage: LocalStorageService, //TODO remove only test
        private readonly investmentsService: InvestmentsService,
        private readonly translationConstants: TranslationConstants,
    ) { }

    public ngOnInit(): void {
        this.dialogRef.addPanelClass(['custom-modal', 'custom-background', 'investor-profile-quiz-modal']);
        this.maskOptions = this.valueConverterService.getOptionsForCurrencyMask();
    }

    public async initData(): Promise<void> {
        this.loading = true;
        return new Promise((resolve, reject) => {
            this.investmentsService.getUserInvestorProfileQuiz(this.loggedUser.id).subscribe(data => {
                this.investorProfileData = data;
                resolve();
            }).add(() => {
                this.loading = false;
            });
        });
    }

    public async getUserLogged(): Promise<void> { //TODO remove
        this.loading = true;
        return new Promise((resolve, reject) => {
            this.accountService.isAuthenticated().subscribe(() => {
                this.accountService.getLoggedUserDetails().subscribe(user => {
                    if (user) {
                        this.loggedUser = user;
                        resolve();
                    }
                });
            }).add(() => {
                this.initData();
            });

        })
    }

    public getYears(date: Date, years: number): Date {
        const newDate: Date = new Date(date);
        newDate.setFullYear(newDate.getFullYear() + years);
        return newDate;
    }

    public getFutureDateRange(startYears: number, endYears: number): { startDate: Date, endDate: Date } {
        const startDate = this.getYears(new Date(), startYears);
        const endDate = this.getYears(new Date(), endYears);
        return { startDate, endDate };
    }

    public async ngAfterViewInit(): Promise<void> {
        await this.getUserLogged();
        setTimeout(() => {
            this.stepper.selectionChange.subscribe(stepper => {
                this.currentStepperIndex = stepper.selectedIndex;
                const matStepLength: number = this.stepper.steps.length;
                this.progressBarWidth = (stepper.selectedIndex * 100) / (matStepLength - 1);
            });
        }, 500);
    }

    public close(): void {
        this.dialogRef.close();
    }

    public nextStepper(): void {
        this.stepper.next();
    }

    public translateKnowledgeLevel(level: EInvestorProfile): string {
        if (!level) {
            return '--';
        } else if (level === EInvestorProfile.BASIC) {
            return this.translationConstants.translate('investments.quiz.profile.basic');
        } else if (level === EInvestorProfile.MODERATE) {
            return this.translationConstants.translate('investments.quiz.profile.moderate');
        } else {
            return this.translationConstants.translate('investments.quiz.profile.advanced');
        }
    }

    public translateInvestmentFrequency(frequency: EInvestmentFrequency): string {
        if (!frequency) {
            return '--';
        } else if (frequency === EInvestmentFrequency.ALWAYS) {
            return this.translationConstants.translate('investments.quiz.profile.always');
        } else if (frequency === EInvestmentFrequency.SOMETIMES) {
            return this.translationConstants.translate('investments.quiz.profile.sometimes');
        } else {
            return this.translationConstants.translate('investments.quiz.profile.never');
        }
    }

    public translateInvestmentRisk(risk: EInvestmentRisk): string {
        if (!risk) {
            return '--';
        } else if (risk === EInvestmentRisk.LITTLE) {
            return this.translationConstants.translate('investments.quiz.profile.littleRisk');
        } else if (risk === EInvestmentRisk.AVERAGE) {
            return this.translationConstants.translate('investments.quiz.profile.averageRisk');
        } else {
            return this.translationConstants.translate('investments.quiz.profile.veryRisk');
        }
    }

    public navigateForwardToStep(step: number): void {
        if (step === 4) {
            if (!this.investorProfileData.amount_invested_fixed_income) {
                this.stepper.selectedIndex = 5;
            } else {
                this.stepper.selectedIndex = 6;
            }
        } else if (step === 5) {
            this.stepper.selectedIndex = 6;
        } else if (step === 6) {
            if (!this.investorProfileData.amount_invested_variable_income) {
                this.stepper.selectedIndex = 7;
            } else {
                this.stepper.selectedIndex = 8;
            }
        } else {
            this.stepper.selectedIndex = 8;
        }
    }

    public navigateBackToStep(step: number): void {
        if (step === 6) {
            if (this.investorProfileData.amount_invested_variable_income) {
                this.stepper.selectedIndex = 4;
            } else if (this.investorProfileData.amount_invested_fixed_income && !this.investorProfileData.amount_invested_variable_income) {
                this.stepper.selectedIndex = 4;
            } else {
                this.stepper.selectedIndex = 5;
            }
        } else if (step === 7) {
            if (this.investorProfileData.amount_invested_fixed_income) {
                this.stepper.selectedIndex = 4;
            }
        } else if (step === 8) {
            if (this.investorProfileData.amount_invested_variable_income) {
                this.stepper.selectedIndex = 6
            } else {
                this.stepper.selectedIndex = 7;
            }
        }
    }

    public saveInvestmentQuis(): void {
        //TODO send quiz
        this.saveQuizLoading = true;
        setTimeout(() => {
            this.localStorage.set(LocalStorageKeys.USER_LOGGED_KEY, this.formatControlFormValues()); //TODO remove only test
            this.loggedUser.investor_profile = this.localStorage.get(LocalStorageKeys.USER_LOGGED_KEY).investor_profile; //TODO Remove
            this.translateInvestorProfile();
            this.stepper.next();
            this.saveQuizLoading = false;
        }, 3000)
    }

    public goToInvestmentsPage(): void {
        this.dialogRef.close();
        this.router.navigate(['/investments']);
    }

    public formatControlFormValues(): UserLoggedModel { //TODO Remove only test
        const formattedControlData: UserLoggedModel = {
            ...this.loggedUser,
            investor_profile: this.investorProfileData?.level_investment_knowledge,
        }

        return formattedControlData;
    }

    public translateInvestorProfile(): { title: string, text: string } { //TODO check rule to define investor profile.
        const firstNameUpper: string = this.capitalizeFirstLetter(this.loggedUser?.firstName);

        if (this.loggedUser?.investor_profile === EInvestorProfile.BASIC) {
            return {
                title: this.translationConstants.translate(`${firstNameUpper}, ${this.translationConstants.translate('investments.quiz.profile.basic.title')}`),
                text: this.translationConstants.translate('investments.quiz.profile.basic.text')
            }
        } else if (this.loggedUser?.investor_profile === EInvestorProfile.MODERATE) {
            return {
                title: this.translationConstants.translate(`${firstNameUpper}, ${this.translationConstants.translate('investments.quiz.profile.moderate.title')}`),
                text: this.translationConstants.translate('investments.quiz.profile.moderate.text')
            }
        } else if (this.loggedUser?.investor_profile === EInvestorProfile.ADVANCED) {
            return {
                title: this.translationConstants.translate(`${firstNameUpper}, ${this.translationConstants.translate('investments.quiz.profile.advanced.title')}`),
                text: this.translationConstants.translate('investments.quiz.profile.advanced.text')
            }
        }
    }

    public capitalizeFirstLetter(value: string): string {
        return value?.charAt(0)?.toUpperCase() + value?.slice(1);
    }
}