export const environment = {
    apiBaseUrl: 'API_BASE_URL',
    pageTitle: 'B4 - Bolsa Digital',
    isCapacitor:false,
    mockExchange: false,
    tokenIdWall: 'U2FsdGVkX1+EjulaweNwV72MU0aYVocEqCS1Z5wnY4Fyewe66g==',
    projectName: 'B4',
    firstRoute: '/tokenization',
    graphUrl: '',
    showGraph: false,
    pixelId: '',
    BankingCheckout: false,
    oneSignalId: '',
    baseUrl: 'b4.capital',
    showAccountImage: false,
    collectionId: 'b8087b4c-5b9d-4bb1-acb6-83a917b92444',
    tokenExchange: 'BRZ',
    isKrakenIntegrationEnabled: false,
    signupExternal: false,
    signupURL: '',
    WS_ENDPOINT: "",
    decimalPlaces: '1.3-3',
    decimalsPlacesBought: 3,
    mainRoute: 'account/login',
    showHeaderBuy: false,
    billNameCompany:"B4",
    companyName: 'B4',
    cnpj: '',
    waterMark: false,
    webService: 'ws://54.144.94.179:5000',
    priority: 'dark'
};