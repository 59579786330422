<div class="resell-asset-modal modal">
    <div class="modal-header">
        <h1>{{ 'myAssets.modalAuction.title' | translate }}</h1>
        <mat-icon class="close" mat-dialog-close>close</mat-icon>
    </div>
 
    <div class="asset-detail">
        <img class="asset-img" [src]="getMainPhoto(asset)" [alt]="asset?.description">
        <div>
            <p class="asset-name">{{ asset?.name }}</p>
            <p class="asset-identity">{{ 'myAssets.id' | translate }} {{ asset?.blockchainId }}</p>     
        </div>
    </div>

    <label class="input-label">{{ ('myAssets.modalAuction.minimumPrice' | translate) + ' (' + fiatCurrency.currency + ')?' }}</label>
    <div class="input-icon">
        <input type="text" class="input etc" placeholder="0,00" (keypress)="onKeyPressEvent($event)" [(ngModel)]="minimumPrice" appMaskDecimalNumber >
    </div>

    <label class="input-label">{{ ('myAssets.modalAuction.deadline' | translate) + '?' }}</label>
    <div class="inline-input-date">
        <input class="input" [matDatepicker]="pickerInitialDate" [matDatepickerFilter]="dateFilter" [(ngModel)]="deadLine" disabled mask="00/00/0000">
        <mat-datepicker-toggle matSuffix [for]="pickerInitialDate">
            <mat-icon matDatepickerToggleIcon>calendar_month</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker panelClass="datepicker" #pickerInitialDate disabled="false"></mat-datepicker>
    </div>

    <div class="modal-buttons">
        <button mat-flat-button class="btn outlined" (click)="closeModal()">{{ 'button.cancel' | translate }}</button>
        <button [disabled]="checkValue()" mat-flat-button class="btn default" (click)="performTransaction()">{{ 'myAssets.modalAuction.aution' | translate }}</button>
    </div>
    <mat-progress-spinner *ngIf="loading" class="spinner" [mode]="'indeterminate'"></mat-progress-spinner>
</div>