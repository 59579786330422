export enum MultiplatFormEnum {
    WALLET = 'WALLET',
    BANKING = 'BANKING',
    CROWDFUNDING = 'CROWDFUNDING',
    EXCHANGE = 'EXCHANGE',
    MARKETPLACE = 'MARKETPLACE',
    TOKENIZATION = 'TOKENIZATION',
    EVENTS = 'EVENTS',
    CHAT = 'CHAT',
    INVESTMENTS = 'INVESTMENTS'
}