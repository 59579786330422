import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class InternalNavigationService {

    // Used to reload the balance in the sidebar.component.ts inside the internal navigation.
    public reloadBalance: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public shouldReloadBalance$: Observable<boolean> = this.reloadBalance.asObservable();

    // Used to reload the internal navigation page when the currency is changed.
    public reloadInternalPage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public shouldReloadInternalPage$: Observable<boolean> = this.reloadInternalPage.asObservable();

    constructor() { }
}