<div class="investor-profile-quiz column" [ngClass]="{'modal-height' : loading}">
    <div class="inline gap-10">
        <div class="progress-bar">
            <div class="progress-bar__value" [ngStyle]="{'width.%' : progressBarWidth}"></div>
        </div>
        <mat-icon class="close" (click)="close()">close</mat-icon>
    </div>

    <mat-horizontal-stepper [linear]="false" #stepper class="investor-profile-quiz-stepper"
        animationDuration="500" *ngIf="!loading">

        <!-- Step 1 Investor Profile -->
        <mat-step>
            <div class="modal-header">
                <h1>{{ 'investments.quiz.knowledge' | translate }}</h1>
            </div>
            <div class="radio-buttons">
                <mat-radio-group [(ngModel)]="investorProfileData.level_investment_knowledge" class="column">
                    <mat-radio-button class="small-label radio-in-themes radio-options"
                        *ngFor="let level of allKnowledgeLevel" [value]="level?.code">
                        <div class="column radio-text gap-5">
                            <strong>{{ translateKnowledgeLevel(level?.code) }}</strong>
                            <span>{{ level?.text }}</span>
                        </div>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="buttons">
                <button mat-flat-button class="btn default" (click)="nextStepper()" [disabled]="!investorProfileData.level_investment_knowledge">
                    {{ 'button.next' | translate }}
                </button>
            </div>
        </mat-step>

        <!-- Step 2 Professional Experience -->
        <mat-step>
            <div class="modal-header">
                <h1>{{ 'investments.quiz.experience' | translate }}</h1>
            </div>
            <div class="radio-buttons">
                <mat-radio-group [(ngModel)]="investorProfileData.understanding_investments" class="column">
                    <mat-radio-button class="small-label radio-in-themes radio-options"
                        *ngFor="let type of allTrainingProfessionalExperienceOptions" [value]="type?.code">
                        <div class="column radio-text gap-5">
                            <span>{{ type?.text }}</span>
                        </div>
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="buttons">
                <button mat-flat-button class="btn outlined" matStepperPrevious>
                    {{ 'button.back' | translate }}
                </button>
                <button mat-flat-button class="btn default" (click)="nextStepper()"
                    [disabled]="!investorProfileData.understanding_investments">
                    {{ 'button.next' | translate }}
                </button>
            </div>
        </mat-step>

        <!-- Step 3 Mensal Value -->
        <mat-step>
            <div class="modal-header">
                <h1>{{ 'investments.quiz.mensalValue' | translate }}</h1>
            </div>
            <div class="radio-buttons">
                <mat-radio-group [(ngModel)]="investorProfileData.unexpected_monthly_amount" class="column">
                    <mat-radio-button class="small-label radio-in-themes radio-options" [value]="false">
                        <div class="column radio-text gap-5">
                            <span>{{ 'investments.quiz.no' | translate }}</span>
                        </div>
                    </mat-radio-button>
                    <mat-radio-button class="small-label radio-in-themes radio-options" [value]="true">
                        <div class="column radio-text gap-5">
                            <span>{{ 'investments.quiz.yes' | translate }}</span>
                        </div>
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="buttons">
                <button mat-flat-button class="btn outlined" matStepperPrevious>
                    {{ 'button.back' | translate }}
                </button>
                <button mat-flat-button class="btn default" (click)="nextStepper()"
                    [disabled]="investorProfileData.unexpected_monthly_amount === null">
                    {{ 'button.next' | translate }}
                </button>
            </div>
        </mat-step>

        <!-- Step 4 Investment Frequency -->
        <mat-step>
            <div class="modal-header">
                <h1>{{ 'investments.quiz.frequency' | translate }}</h1>
            </div>
            <div class="radio-buttons">
                <mat-radio-group [(ngModel)]="investorProfileData.investment_frequency" class="column">
                    <mat-radio-button class="small-label radio-in-themes radio-options"
                        *ngFor="let frequency of allInvestmentFrequencyOptions" [value]="frequency?.code">
                        <div class="column radio-text gap-5">
                            <strong>{{ translateInvestmentFrequency(frequency?.code) }}</strong>
                            <span>{{ frequency?.text }}</span>
                        </div>
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="buttons">
                <button mat-flat-button class="btn outlined" matStepperPrevious>
                    {{ 'button.back' | translate }}
                </button>
                <button mat-flat-button class="btn default" (click)="nextStepper()"
                    [disabled]="!investorProfileData.investment_frequency">
                    {{ 'button.next' | translate }}
                </button>
            </div>
        </mat-step>

        <!-- Step 5 Amount Invested -->
        <mat-step>
            <div class="modal-header">
                <h1>{{ 'investments.quiz.amountInvested' | translate }}</h1>
            </div>
            <div class="radio-buttons">
                <input class="input" [(ngModel)]="investorProfileData.amount_invested_fixed_income"
                [placeholder]="'placeholder.insert' | translate" currencyMask
                [options]="maskOptions"
            >
            </div>

            <div class="inline gap-5 arrow-button" (click)="investorProfileData.amount_invested_fixed_income = null; navigateForwardToStep(4)">
                <span>{{ 'investments.quiz.dontHave' | translate }}</span>
                <mat-icon class="arrow-forward">arrow_forward</mat-icon>
            </div>

            <div class="buttons">
                <button mat-flat-button class="btn outlined" matStepperPrevious>
                    {{ 'button.back' | translate }}
                </button>
                <button mat-flat-button class="btn default" (click)="navigateForwardToStep(4)"
                    [disabled]="!investorProfileData.amount_invested_fixed_income">
                    {{ 'button.next' | translate }}
                </button>
            </div>
        </mat-step>

        <!-- Step 6 Invested Fixed Income-->
        <mat-step>
            <div class="modal-header">
                <h1>{{ 'investments.quiz.investedFixedIncome' | translate }}</h1>
            </div>
            <div class="radio-buttons">
                <input class="input" [(ngModel)]="investorProfileData.amount_invested_fixed_income_last_years"
                [placeholder]="'placeholder.insert' | translate" currencyMask
                [options]="maskOptions"
            >
            </div>

            <div class="inline gap-5 arrow-button" (click)="investorProfileData.amount_invested_fixed_income_last_years = null; navigateForwardToStep(5)">
                <span>{{ 'investments.quiz.noInvest' | translate }}</span>
                <mat-icon class="arrow-forward">arrow_forward</mat-icon>
            </div>

            <div class="buttons">
                <button mat-flat-button class="btn outlined" matStepperPrevious>
                    {{ 'button.back' | translate }}
                </button>
                <button mat-flat-button class="btn default" (click)="navigateForwardToStep(5)"
                    [disabled]="!investorProfileData.amount_invested_fixed_income_last_years">
                    {{ 'button.next' | translate }}
                </button>
            </div>
        </mat-step>

        <!-- Step 7 Invested Other Derivatives -->
        <mat-step>
            <div class="modal-header">
                <h1>{{ 'investments.quiz.investedOtherDerivatives' | translate }}</h1>
            </div>

            <div class="radio-buttons">
                <input class="input" [(ngModel)]="investorProfileData.amount_invested_variable_income"
                [placeholder]="'placeholder.insert' | translate" currencyMask
                [options]="maskOptions"
            >
            </div>

            <div class="inline gap-5 arrow-button" (click)="investorProfileData.amount_invested_variable_income = null; navigateForwardToStep(6)">
                <span>{{ 'investments.quiz.dontHave' | translate }}</span>
                <mat-icon class="arrow-forward">arrow_forward</mat-icon>
            </div>

            <div class="buttons">
                <button mat-flat-button class="btn outlined" (click)="navigateBackToStep(6)">
                    {{ 'button.back' | translate }}
                </button>
                <button mat-flat-button class="btn default" (click)="navigateForwardToStep(6)"
                    [disabled]="!investorProfileData.amount_invested_variable_income">
                    {{ 'button.next' | translate }}
                </button>
            </div>
        </mat-step>

        <!-- Step 8 Invested Variable Income -->
        <mat-step>
            <div class="modal-header">
                <h1>{{ 'investments.quiz.investedVariableIncome' | translate }}</h1>
            </div>
            <div class="radio-buttons">
                <input class="input" [(ngModel)]="investorProfileData.amount_invested_variable_income_last_years"
                [placeholder]="'placeholder.insert' | translate" currencyMask
                [options]="maskOptions"
            >
            </div>

            <div class="inline gap-5 arrow-button" (click)="investorProfileData.amount_invested_variable_income_last_years = null; navigateForwardToStep(7)">
                <span>{{ 'investments.quiz.noInvest' | translate }}</span>
                <mat-icon class="arrow-forward">arrow_forward</mat-icon>
            </div>

            <div class="buttons">
                <button mat-flat-button class="btn outlined" matStepperPrevious>
                    {{ 'button.back' | translate }}
                </button>
                <button mat-flat-button class="btn default" (click)="navigateForwardToStep(7)"
                    [disabled]="!investorProfileData.amount_invested_variable_income_last_years">
                    {{ 'button.next' | translate }}
                </button>
            </div>
        </mat-step>

        <!-- Step 9 Investment Risk -->
        <mat-step>
            <div class="modal-header">
                <h1>{{ 'investments.quiz.risk' | translate }}</h1>
            </div>
            <div class="radio-buttons">
                <mat-radio-group [(ngModel)]="investorProfileData.investment_risk" class="column">
                    <mat-radio-button class="small-label radio-in-themes radio-options"
                        *ngFor="let risk of allInvestmentRiskOptions" [value]="risk?.code">
                        <div class="column radio-text gap-5">
                            <strong>{{ translateInvestmentRisk(risk?.code) }}</strong>
                            <span>{{ risk?.text }}</span>
                        </div>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="buttons">
                <button mat-flat-button class="btn outlined" (click)="navigateBackToStep(8)">
                    {{ 'button.back' | translate }}
                </button>
                <button mat-flat-button class="btn default" (click)="nextStepper()" [disabled]="!investorProfileData.investment_risk">
                    {{ 'button.next' | translate }}
                </button>
            </div>
        </mat-step>

        <!-- Step 10 Date -->
        <mat-step>
            <div class="modal-header">
                <h1>{{ 'investments.quiz.date' | translate }}</h1>
            </div>
            <div class="radio-buttons">
                <mat-radio-group [(ngModel)]="investorProfileData.when_use_investment" class="column">
                    <mat-radio-button class="small-label radio-in-themes radio-options"
                        *ngFor="let date of allInvestmentsDate" [value]="date?.date">
                        <div class="column radio-text gap-5">
                            <span>{{ date?.text }}</span>
                        </div>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="buttons">
                <button mat-flat-button class="btn outlined" matStepperPrevious>
                    {{ 'button.back' | translate }}
                </button>
                <button mat-flat-button class="btn default" (click)="saveInvestmentQuis()" [disabled]="!investorProfileData.when_use_investment">
                    {{ 'button.next' | translate }}
                </button>
            </div>
        </mat-step>

        <!--Step 11 Success-->
        <mat-step>
            <div class="investor-profile-success full-page centered">
                <img class="success-img" src="../../../assets/images/check.png" alt="success">
                <p class="success-text">{{ translateInvestorProfile()?.title }}</p>
                <p class="success-text">{{ translateInvestorProfile()?.text }}</p>
                <p class="to-leave" (click)="goToInvestmentsPage()">{{ 'investments.quiz.explore' | translate }}</p>
            </div>
        </mat-step>

    </mat-horizontal-stepper>
</div>
<mat-progress-spinner *ngIf="loading || saveQuizLoading" class="spinner" [mode]="'indeterminate'"></mat-progress-spinner>