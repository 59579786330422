export interface IInvestments {
    name: string;
    icon: string;
    category: string;
}

export interface InvestmentItem {
    id: string;
    name: string;
    description: string;
    acronym: string;
    value: number;
    type: {
        name: EInvestmentType | string,
        description: string;
    };
    icon: string;
}

export interface InvestmentOrder {
    quantity: number;
    estimated_total: number;
}

export interface InvestmentCategory {
    id: string;
    percentage: number;
    expiration_date: string;
    issuer?: string;
    minimum_to_buy: number;
    category: EInvestmentCategory | string;
    type?: EDirectTreasuryType | string;
}

export interface IUserInvestment {
    id: string;
    value: number;
    name?: string;
    expiration_date?: string;
    category: EInvestmentCategory | string;
    type?: EDirectTreasuryType | EInvestmentType | string;
}

export class InvestorProfile { //Check all properties.
    level_investment_knowledge: EInvestorProfile | string;
    understanding_investments: EKnowledgeInvestment | string;
    unexpected_monthly_amount: boolean;
    investment_frequency: EInvestmentFrequency | string;
    amount_invested_fixed_income: number;
    amount_invested_fixed_income_last_years: number;
    amount_invested_variable_income: number;
    amount_invested_variable_income_last_years: number;
    investment_risk: EInvestmentRisk;
    when_use_investment: Date | string;
}

export class IVariableIncomeInvestment {
    id: string;
    name: string;
    value: number;
    risk: string;
    description: string;
    profile: string;
    strategy: string;
    minimum_to_buy: number;
}

export enum EInvestorProfile {
    BASIC = 'basic',
    MODERATE = 'moderate',
    ADVANCED = 'advanced'
}

export enum EInvestmentFrequency {
    ALWAYS = 'always',
    SOMETIMES = 'sometimes',
    NEVER = 'never'
}

export enum EInvestmentRisk {
    LITTLE = 'little',
    AVERAGE = 'average',
    VERY = 'very'
}

export enum EKnowledgeInvestment {
    TRAINING = 'training',
    PROFESSIONAL_EXPERIENCE = 'professional_experience',
    TRAINING_PROFESSIONAL_EXPERIENCE = 'training_professional_experience',
    NONE ='none'
}

export class DefaultListValues {
    public text?: string;
    public code?: string | Date;
    public selected?: boolean;
    public date?: Date | { startDate: Date, endDate: Date };
}

export enum EInvestmentType {
    ETFs = 'ETFs',
    FIIs = 'FIIs',
    BDRs = 'BDRs',
    BRAZILIAN_STOCKS = 'BRAZILIAN_STOCKS',
    ALL = 'all' //Used only in Frontend
}

export enum EInvestmentFilter {
    'ETFs' = 0,
    'FIIs'= 1,
    'BDRs' = 2,
    'BRAZILIAN_STOCKS' = 3
};

export enum EDirectTreasuryFilter {
    'SELIC' = 0,
    'IPCA+' = 1,
    'PREFIXADO' = 2,
    'Renda+' = 3,
    'Educa+' = 4
}

export enum EInvestmentCategory {
    CDB = 'cdb',
    LCI_LCA = 'lci-lca',
    INVESTMENT_FUND = 'investment-fund',
    DIRECT_TREASURY = 'direct-treasury',
    STOCK_EXCHANGE = 'stock-exchange',
    ALL = 'all' //Used only in Frontend
}

export enum EDirectTreasuryType {
    SELIC = 'SELIC',
    IPCA = 'IPCA',
    PREFIXADO = 'PREFIXADO',
    RENDA = 'RENDA+',
    EDUCA = 'EDUCA+',
    ALL = 'all' //Used only in Frontend
}