import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { EInvestmentRisk, IInvestments, IUserInvestment, IVariableIncomeInvestment, InvestmentCategory, InvestmentItem, InvestorProfile } from '../../models/investments.model';
import { HttpClient } from '@angular/common/http';
import { v4 as uuid } from 'uuid';
import { delay } from 'rxjs/operators';

const allInvestmentsStockExchange: Array<InvestmentItem> = [
    //ETFs
    { id: 'ac85b5f4-dbe3-418e-9237-422c0a405e8b', name: 'Ishares Ibovespa', description: 'tchau', acronym: 'BOVA11', value: 116.12, type: { name: 'ETFs', description: 'ETF signifca "Fundos Negociados em Bolsas". É um tipo de investimento em que várias pessoas compram cotas de um fundo administrado por uma gestora especializada. Um ETF está sempre atrelado a algum índice de referência da bolsa de valores. Isso significa que ele é formado para "imitar" o seu indicador e, assim, replicar seus resultados. Por exemplo, se o índice de referência subir, o ETF baseado nele também deve subir na mesma proporção.'}, icon: null },
    { id: '298108c9-310c-41f8-a9bd-cd570c2c1391', name: 'IT NOW IBOV', description: '', acronym: 'BOVV11', value: 121.83, type: { name: 'ETFs', description: 'ETF signifca "Fundos Negociados em Bolsas". É um tipo de investimento em que várias pessoas compram cotas de um fundo administrado por uma gestora especializada. Um ETF está sempre atrelado a algum índice de referência da bolsa de valores. Isso significa que ele é formado para "imitar" o seu indicador e, assim, replicar seus resultados. Por exemplo, se o índice de referência subir, o ETF baseado nele também deve subir na mesma proporção.' }, icon: null },
    { id: "df3ec56a-6d2c-451d-97b5-2d8f881ca774", name: "iShares Small Caps", description: "Se você procura empresas menores com alto potencial de valorização, o SMALL11 pode ser uma boa opção. O SMALL11 é um fundo que busca seguir o índice da B3 que reúne as principais small caps - empresas de menor capitalização da bolsa. Ao investir no fundo é como se você estivesse investindo em mais de 80 empresas juntas, sendo as top 10: \n- Eneva \n- Cogna \n- Bradespar \n- Qualicorp \n- Fleury \n- Yduqs \n- Azul \n- Banco Inter \n- BR Malls \n- IRB Brasil \nO critério para a seleção das empresas no índice não considera a análise de fundamentos, e os preços podem ter uma maior volatilidade em relação às grandes empresas.", acronym: "SMALL11", value: 93.30, type: { name: "ETFs", description: "ETF signifca \"Fundos Negociados em Bolsas\". \nÉ um tipo de investimento em que várias pessoas compram cotas de um fundo administrado por uma gestora especializada. \nUm ETF está sempre atrelado a algum índice de referência da bolsa de valores. Isso significa que ele é formado para \"imitar\" o seu indicador e, assim, replicar seus resultados. Por exemplo, se o índice de referência subir, o ETF baseado nele também deve subir na mesma proporção." }, icon: null},
    { id: uuid(), name: 'iShares S&P 500', description: '', acronym: 'IVVB11', value: 331.93, type: { name: 'ETFs', description: 'ETF signifca "Fundos Negociados em Bolsas". É um tipo de investimento em que várias pessoas compram cotas de um fundo administrado por uma gestora especializada. Um ETF está sempre atrelado a algum índice de referência da bolsa de valores. Isso significa que ele é formado para "imitar" o seu indicador e, assim, replicar seus resultados. Por exemplo, se o índice de referência subir, o ETF baseado nele também deve subir na mesma proporção.' }, icon: null },
    { id: uuid(), name: 'IT NOW SPXI', description: '', acronym: 'SPXI11', value: 323.55, type: { name: 'ETFs', description: 'ETF signifca "Fundos Negociados em Bolsas". É um tipo de investimento em que várias pessoas compram cotas de um fundo administrado por uma gestora especializada. Um ETF está sempre atrelado a algum índice de referência da bolsa de valores. Isso significa que ele é formado para "imitar" o seu indicador e, assim, replicar seus resultados. Por exemplo, se o índice de referência subir, o ETF baseado nele também deve subir na mesma proporção.' }, icon: null },    { id: uuid(), name: 'Kinea Rendimentos Imobiliários', description: '', acronym: 'KNCR11', value: 104.04, type: { name: 'FIIs', description: '' }, icon: null },
    //FIIs
    { id: uuid(), name: 'CSHG Logística', description: '', acronym: 'HGLG11', value: 158.56, type: { name: 'FIIs', description: 'Também chamados de FIIs, os Fundos Imobiliários funcionam como outros fundos de investimentos, mas são focados em imóveis ou em ativos financeiros que fazem parte do setor de imóveis. Nos fundos, várias pessoas são "donas" de pequenas partes, que são chamadas de cotas. Por isso, ao comprar cotas de um FII, você passa a ter pedacinhos dos imóveis que fazem parto do fundo ou participação em um conjunto de ativos financeiros do setor imobiliário.' }, icon: null },
    { id: uuid(), name: 'Kinea Renda Imobiliária', description: '', acronym: 'KNRI11', value: 153.82, type: { name: 'FIIs', description: 'Também chamados de FIIs, os Fundos Imobiliários funcionam como outros fundos de investimentos, mas são focados em imóveis ou em ativos financeiros que fazem parte do setor de imóveis. Nos fundos, várias pessoas são "donas" de pequenas partes, que são chamadas de cotas. Por isso, ao comprar cotas de um FII, você passa a ter pedacinhos dos imóveis que fazem parto do fundo ou participação em um conjunto de ativos financeiros do setor imobiliário.' }, icon: null },
    { id: uuid(), name: 'Iridium Recebíveis Imobiliários', description: '', acronym: 'IRDM11', value: 72.16, type: { name: 'FIIs', description: 'Também chamados de FIIs, os Fundos Imobiliários funcionam como outros fundos de investimentos, mas são focados em imóveis ou em ativos financeiros que fazem parte do setor de imóveis. Nos fundos, várias pessoas são "donas" de pequenas partes, que são chamadas de cotas. Por isso, ao comprar cotas de um FII, você passa a ter pedacinhos dos imóveis que fazem parto do fundo ou participação em um conjunto de ativos financeiros do setor imobiliário.' }, icon: null },
    //BDRs
    { id: uuid(), name: 'Apple', description: '', acronym: 'AAPL34', value: 58.33, type: { name: 'BDRs', description: '' }, icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Apple_logo_black.svg/1200px-Apple_logo_black.svg.png' },
    { id: uuid(), name: 'Amazon', description: '', acronym: 'AMZO34', value: 49.60, type: { name: 'BDRs', description: '' }, icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Amazon_logo.svg/1024px-Amazon_logo.svg.png' },
    { id: uuid(), name: 'Microsoft', description: '', acronym: 'MSFT34', value: 101.24, type: { name: 'BDRs', description: '' }, icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/96/Microsoft_logo_%282012%29.svg/1200px-Microsoft_logo_%282012%29.svg.png' },
    { id: uuid(), name: 'Berkshire Hathaway', description: '', acronym: 'BERK34', value: 110.77, type: { name: 'BDRs', description: '' }, icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Berkshire-Hathaway-Logo.svg/1920px-Berkshire-Hathaway-Logo.svg.png?20140429112701' },
    { id: uuid(), name: 'Coca Cola', description: '', acronym: 'COCA34', value: 56.65, type: { name: 'BDRs', description: '' }, icon: 'https://upload.wikimedia.org/wikipedia/commons/c/ce/Coca-Cola_logo.svg' },
    //BRAZILIAN_STOCKS
    { id: uuid(), name: 'Vale', description: '', acronym: 'VALE3', value: 60.66, type: { name: 'BRAZILIAN_STOCKS', description: '' }, icon: 'https://upload.wikimedia.org/wikipedia/pt/c/cc/Logotipo_Vale.svg' },
    { id: uuid(), name: 'Itaú Unibanco', description: '', acronym: 'ITUB4', value: 31.88, type: { name: 'BRAZILIAN_STOCKS', description: '' }, icon: 'https://upload.wikimedia.org/wikipedia/commons/1/19/Ita%C3%BA_Unibanco_logo_2023.svg' },
    { id: uuid(), name: 'Petrobras', description: '', acronym: 'PETR4', value: 35.90, type: { name: 'BRAZILIAN_STOCKS', description: '' }, icon: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Logo_petrobras.gif' },
    { id: uuid(), name: 'Banco Bradesco', description: '', acronym: 'BBDC4', value: 12.38, type: { name: 'BRAZILIAN_STOCKS', description: '' }, icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/8a/Banco_Bradesco_logo.svg/1920px-Banco_Bradesco_logo.svg.png?20221208191211' },
    { id: uuid(), name: 'B3 Brasil Bolsa Balcão', description: '', acronym: 'B3SA3', value: 10.61, type: { name: 'BRAZILIAN_STOCKS', description: '' }, icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d7/B3_logo.png' }
];

const allFavoriteList: Array<InvestmentItem> = [
    { id: 'ac85b5f4-dbe3-418e-9237-422c0a405e8b', name: 'Ishares Ibovespa', description: 'tchau', acronym: 'BOVA11', value: 116.12, type: { name: 'ETFs', description: 'oi'}, icon: null },
    { id: '298108c9-310c-41f8-a9bd-cd570c2c1391', name: 'IT NOW IBOV', description: '', acronym: 'BOVV11', value: 121.83, type: { name: 'ETFs', description: 'oi' }, icon: null },
    { id: 'df3ec56a-6d2c-451d-97b5-2d8f881ca774', name: 'iShares Small Caps', description: '', acronym: 'SMALL11', value: 93.30, type: { name: 'ETFs', description: 'oi' }, icon: null },
];

const investmentsByCategory: Array<InvestmentCategory> = [
    {
        id: 'ac85b5f4-dbe3-418e-9237-422c0a405e8b',
        percentage: 98.5,
        expiration_date: new Date('2025-06-30').toISOString(),
        issuer: 'Banco XYZ',
        minimum_to_buy: 1000,
        category: 'cdb'
    },
    {
        id: 'ac85b5f4-dbe3-418e-9237-422c0a405e8c',
        percentage: 102.3,
        expiration_date: new Date('2026-12-31').toISOString(),
        issuer: 'Banco Original',
        minimum_to_buy: 5000,
        category: 'cdb'
    },
    {
        id: uuid(),
        percentage: 100.0,
        expiration_date: new Date('2024-09-15').toISOString(),
        issuer: 'Banco Axia',
        minimum_to_buy: 2000,
        category: 'cdb'
    },
    {
        id: uuid(),
        percentage: 95.0,
        expiration_date: new Date('2027-01-20').toISOString(),
        issuer: 'Banco GHI',
        minimum_to_buy: 3000,
        category: 'cdb'
    },
    {
        id: 'ac85b5f4-dbe3-418e-9237-422c0a405e8d',
        percentage: 101.5,
        expiration_date: new Date('2025-11-30').toISOString(),
        issuer: 'Banco JKL',
        minimum_to_buy: 1500,
        category: 'lci-lca'
    },
    {
        id: 'ac85b5f4-dbe3-418e-9237-422c0a405e8e',
        percentage: 99.8,
        expiration_date: new Date('2026-05-31').toISOString(),
        issuer: 'Banco MNO',
        minimum_to_buy: 2500,
        category: 'lci-lca'
    },
    {
        id: uuid(),
        percentage: 97.5,
        expiration_date: new Date('2024-08-20').toISOString(),
        issuer: 'Banco PQR',
        minimum_to_buy: 3000,
        category: 'lci-lca'
    },
    {
        id: uuid(),
        percentage: 103.0,
        expiration_date: new Date('2027-03-25').toISOString(),
        issuer: 'Banco STU',
        minimum_to_buy: 1000,
        category: 'lci-lca'
    },
    // SELIC
    {
        id: 'ac85b5f4-dbe3-418e-9237-422c0a405e8f',
        percentage: 0.16,
        expiration_date: new Date('2025-12-31').toISOString(),
        minimum_to_buy: 149.12,
        category: 'direct-treasury',
        type: 'SELIC'
    },
    {
        id: 'ac85b5f4-dbe3-418e-9237-422c0a405e8g',
        percentage: 0.08,
        expiration_date: new Date('2027-03-01').toISOString(),
        minimum_to_buy: 200,
        category: 'direct-treasury',
        type: 'SELIC'
    },
    {
        id: uuid(),
        percentage: 105,
        expiration_date: new Date('2027-12-31').toISOString(),
        minimum_to_buy: 150,
        category: 'direct-treasury',
        type: 'SELIC'
    },
    // IPCA
    {
        id: 'ac85b5f4-dbe3-418e-9237-422c0a405e8h',
        percentage: 6.41,
        expiration_date: new Date('2045-05-15').toISOString(),
        minimum_to_buy: 35.50,
        category: 'direct-treasury',
        type: 'IPCA'
    },
    {
        id: uuid(),
        percentage: 6.4,
        expiration_date: new Date('2035-05-15').toISOString(),
        minimum_to_buy: 200,
        category: 'direct-treasury',
        type: 'IPCA'
    },
    {
        id: uuid(),
        percentage: 105,
        expiration_date: new Date('2027-12-31').toISOString(),
        minimum_to_buy: 150,
        category: 'direct-treasury',
        type: 'IPCA'
    },
    // PREFIXADO
    {
        id: 'ac85b5f4-dbe3-418e-9237-422c0a405e8i',
        percentage: 12.46,
        expiration_date: new Date('2025-12-31').toISOString(),
        minimum_to_buy: 32.75,
        category: 'direct-treasury',
        type: 'PREFIXADO'
    },
    {
        id: uuid(),
        percentage: 11.85,
        expiration_date: new Date('2027-01-01').toISOString(),
        minimum_to_buy: 200,
        category: 'direct-treasury',
        type: 'PREFIXADO'
    },
    {
        id: uuid(),
        percentage: 105,
        expiration_date: new Date('2027-12-31').toISOString(),
        minimum_to_buy: 150,
        category: 'direct-treasury',
        type: 'PREFIXADO'
    },
    // RENDA+
    {
        id: 'ac85b5f4-dbe3-418e-9237-422c0a405e8j',
        percentage: 6.44,
        expiration_date: new Date('2065-01-15').toISOString(),
        minimum_to_buy: 31.91,
        category: 'direct-treasury',
        type: 'RENDA+'
    },
    {
        id: uuid(),
        percentage: 6.42,
        expiration_date: new Date('2026-12-31').toISOString(),
        minimum_to_buy: 32.64,
        category: 'direct-treasury',
        type: 'RENDA+'
    },
    {
        id: uuid(),
        percentage: 105,
        expiration_date: new Date('2027-12-31').toISOString(),
        minimum_to_buy: 150,
        category: 'direct-treasury',
        type: 'RENDA+'
    },
    // EDUCA+
    {
        id: 'ac85b5f4-dbe3-418e-9237-422c0a405e8k',
        percentage: 6.4,
        expiration_date: new Date('2030-01-15').toISOString(),
        minimum_to_buy: 46.54,
        category: 'direct-treasury',
        type: 'EDUCA+'
    },
    {
        id: uuid(),
        percentage: 6.4,
        expiration_date: new Date('2031-01-15').toISOString(),
        minimum_to_buy: 200,
        category: 'direct-treasury',
        type: 'EDUCA+'
    },
    {
        id: uuid(),
        percentage: 105,
        expiration_date: new Date('2027-12-31').toISOString(),
        minimum_to_buy: 150,
        category: 'direct-treasury',
        type: 'EDUCA+'
    },
];

const allUserInvestments: Array<IUserInvestment> = [
    {
        id: uuid(),
        value: 105,
        expiration_date: new Date('2027-12-31').toISOString(),
        category: 'direct-treasury',
        type: 'EDUCA+'
    },
    {
        id: uuid(),
        value: 658,
        expiration_date: new Date('2027-12-31').toISOString(),
        category: 'direct-treasury',
        type: 'RENDA+'
    },
    {
        id: uuid(),
        value: 1500,
        expiration_date: new Date('2024-08-20').toISOString(),
        category: 'lci-lca'
    },
    {
        id: uuid(),
        value: 2000,
        expiration_date: new Date('2027-03-25').toISOString(),
        category: 'lci-lca'
    },
    {
        id: uuid(),
        value: 100.0,
        expiration_date: new Date('2024-09-15').toISOString(),
        category: 'cdb'
    },
    {
        id: uuid(),
        value: 95.0,
        expiration_date: new Date('2027-01-20').toISOString(),
        category: 'cdb'
    },
    {
        id: 'd8adc335-9330-4b90-8a1e-61833990bd25',
        name: "Fundo de Ações Alpha",
        value: 15.7,
        category: 'investment-fund'
    },
    {
        id: '775d06e3-2e6f-4a7e-a318-434b4afeb589',
        name: "Fundo Imobiliário Beta",
        value: 8.3,
        category: 'investment-fund'
    },
    { 
        id: 'ac85b5f4-dbe3-418e-9237-422c0a405e8b', 
        name: 'Ishares Ibovespa', 
        value: 116.12,
        category: 'stock-exchange',
        type: 'ETFs'
    },
    { 
        id: uuid(), 
        name: 'CSHG Logística', 
        value: 158.56,
        category: 'stock-exchange',
        type: 'BDRs'
    }
];

const allVariableIncomeInvestments: IVariableIncomeInvestment[] = [
    {
        id: 'd8adc335-9330-4b90-8a1e-61833990bd25',
        name: "Fundo de Ações Alpha",
        value: 15.7,
        risk: "very",
        minimum_to_buy: 1000,
        description: "Fundo focado em ações de empresas emergentes com alto potencial de crescimento. Fundo focado em ações de empresas emergentes com alto potencial de crescimento. Fundo focado em ações de empresas emergentes com alto potencial de crescimento. Fundo focado em ações de empresas emergentes com alto potencial de crescimento.",
        profile: "Este produto consolida experiência e competência traduzindo nas melhores ideias de investimentos em títulos de crédito privado.",
        strategy: "Investimento em empresas de pequeno e médio porte, com potencial de valorização acima da média de mercado."
    },
    {
        id: '775d06e3-2e6f-4a7e-a318-434b4afeb589',
        name: "Fundo Imobiliário Beta",
        value: 8.3,
        risk: "average",
        minimum_to_buy: 500,
        profile: "A gestão segue o modelo de tesouraria utilizado pelos bancos brasileiros e possui um time com habilidades e experiências complementares, além da figura do gestor principal.",
        description: "Fundo focado em imóveis comerciais de alta qualidade em áreas metropolitanas.",
        strategy: "Investimento em propriedades comerciais que geram renda estável através de aluguéis."
    },
    {
        id: 'cb7b59e9-f2a7-4d7c-ade5-ee0795262bea',
        name: "Fundo Multimercado Gamma",
        value: 12.5,
        minimum_to_buy: 100,
        risk: "little",
        profile: "O Fundo Multimercado Gamma busca proporcionar retornos de longo prazo ajustados ao risco através do investimento no mercado de ações.",
        description: "Fundo diversificado que investe em uma combinação de ações, títulos e commodities.",
        strategy: "Estratégia de alocação diversificada para minimizar riscos e maximizar retornos a longo prazo."
    },
    {
        id: uuid(),
        name: "Fundo de Renda Fixa Delta",
        value: 6.0,
        risk: "baixo",
        minimum_to_buy: 3000,
        profile: "Esse fundo visa obter retornos similares à Selic, com taxa de administração inferior a de custódia do Tesouro Direto. Seu principal foco está em investir em títulos públicos federais.",
        description: "Fundo que investe predominantemente em títulos de renda fixa de alta qualidade.",
        strategy: "Foco em títulos públicos e privados de baixo risco, buscando estabilidade e segurança."
    },
    {
        id: uuid(),
        name: "Fundo de Renda Fixa Delta",
        value: 6.0,
        risk: "baixo",
        minimum_to_buy: 30500,
        profile: "Esse fundo visa obter retornos similares à Selic, com taxa de administração inferior a de custódia do Tesouro Direto. Seu principal foco está em investir em títulos públicos federais.",
        description: "Fundo que investe predominantemente em títulos de renda fixa de alta qualidade.",
        strategy: "Foco em títulos públicos e privados de baixo risco, buscando estabilidade e segurança."
    },
    {
        id: uuid(),
        name: "Fundo de Renda Fixa Delta",
        value: 6.0,
        risk: "baixo",
        minimum_to_buy: 7000,
        profile: "Esse fundo visa obter retornos similares à Selic, com taxa de administração inferior a de custódia do Tesouro Direto. Seu principal foco está em investir em títulos públicos federais.",
        description: "Fundo que investe predominantemente em títulos de renda fixa de alta qualidade.",
        strategy: "Foco em títulos públicos e privados de baixo risco, buscando estabilidade e segurança."
    },
    {
        id: uuid(),
        name: "Fundo de Renda Fixa Delta",
        value: 6.0,
        risk: "baixo",
        minimum_to_buy: 80,
        profile: "Esse fundo visa obter retornos similares à Selic, com taxa de administração inferior a de custódia do Tesouro Direto. Seu principal foco está em investir em títulos públicos federais.",
        description: "Fundo que investe predominantemente em títulos de renda fixa de alta qualidade.",
        strategy: "Foco em títulos públicos e privados de baixo risco, buscando estabilidade e segurança."
    },
    {
        id: uuid(),
        name: "Fundo de Renda Fixa Delta",
        value: 6.0,
        risk: "baixo",
        minimum_to_buy: 1000,
        profile: "Esse fundo visa obter retornos similares à Selic, com taxa de administração inferior a de custódia do Tesouro Direto. Seu principal foco está em investir em títulos públicos federais.",
        description: "Fundo que investe predominantemente em títulos de renda fixa de alta qualidade.",
        strategy: "Foco em títulos públicos e privados de baixo risco, buscando estabilidade e segurança."
    },
    {
        id: uuid(),
        name: "Fundo de Renda Fixa Delta",
        value: 6.0,
        risk: "baixo",
        minimum_to_buy: 1000,
        profile: "Esse fundo visa obter retornos similares à Selic, com taxa de administração inferior a de custódia do Tesouro Direto. Seu principal foco está em investir em títulos públicos federais.",
        description: "Fundo que investe predominantemente em títulos de renda fixa de alta qualidade.",
        strategy: "Foco em títulos públicos e privados de baixo risco, buscando estabilidade e segurança."
    },
    {
        id: uuid(),
        name: "Fundo de Renda Fixa Delta",
        value: 6.0,
        minimum_to_buy: 1000,
        risk: "baixo",
        profile: "Esse fundo visa obter retornos similares à Selic, com taxa de administração inferior a de custódia do Tesouro Direto. Seu principal foco está em investir em títulos públicos federais.",
        description: "Fundo que investe predominantemente em títulos de renda fixa de alta qualidade.",
        strategy: "Foco em títulos públicos e privados de baixo risco, buscando estabilidade e segurança."
    }
];

@Injectable({
    providedIn: 'root'
})
export class InvestmentsService {

    private investmentFavoriteList: Array<InvestmentItem> = [];

    constructor(
        private readonly http: HttpClient,
    ) { }

    public getAllInvestmentsCategory(): Observable<Array<IInvestments>> { //TODO return types of investments
        return of([
            {
                name: 'investments.CDB',
                icon: 'signal_cellular_alt',
                category: 'cdb'
            },
            {
                name: 'investments.LCI-LCA',
                icon: 'description',
                category: 'lci-lca'
            },
            {
                name: 'investments.funds',
                icon: 'incomplete_circle',
                category: 'investment-fund'
            },
            {
                name: 'investments.directTreasury',
                icon: 'account_balance',
                category: 'direct-treasury'
            },
            {
                name: 'investments.stock-exchange',
                icon: 'show_chart',
                category: 'stock-exchange'
            }
        ]);
    }

    public getUserInvestorProfileQuiz(userId: string): Observable<InvestorProfile> {
        if (userId) {
            return of({
                level_investment_knowledge: 'moderate',
                understanding_investments: 'training',
                unexpected_monthly_amount: true,
                investment_frequency: 'always',
                amount_invested_fixed_income: 5000,
                amount_invested_fixed_income_last_years: null,
                amount_invested_variable_income: 10000,
                amount_invested_variable_income_last_years: null,
                investment_risk: EInvestmentRisk.VERY,
                when_use_investment: new Date('Mon Jun 16 2025 17:20:00 GMT-0300')
            });
            // return of({
            //     level_investment_knowledge: null,
            //     understanding_investments: null,
            //     unexpected_monthly_amount: null,
            //     investment_frequency: null,
            //     amount_invested_fixed_income: null,
            //     amount_invested_fixed_income_last_years: null,
            //     amount_invested_variable_income: null,
            //     amount_invested_variable_income_last_years: null,
            //     investment_risk: null,
            //     when_use_investment: null
            // });
        }
    }

    public getAllStockExchangeInvestments(): Observable<Array<InvestmentItem>> {
        return of(allInvestmentsStockExchange);
        return throwError(() => new Error('Simulated error.'));
    }

    public addInvestmentToFavorite(element: InvestmentItem): Observable<Array<InvestmentItem>> {
        const investmentIndex: number = this.investmentFavoriteList.findIndex(investment => investment.id === element.id);

        if (investmentIndex === -1) {
            this.investmentFavoriteList.push(element);
        }

        return of(this.investmentFavoriteList).pipe(delay(500));
    }

    public deleteInvestmentFavorite(element: InvestmentItem): Observable<Array<InvestmentItem>> {
        const investmentIndex: number = this.investmentFavoriteList.findIndex(investment => investment.id === element.id);

        if (investmentIndex !== -1) {
            this.investmentFavoriteList.splice(investmentIndex, 1);
        }

        return of(this.investmentFavoriteList).pipe(delay(500));
    }

    public getFavoriteInvestments(): Observable<Array<InvestmentItem>> { //TODO implement backend
        // return of(this.investmentFavoriteList).pipe(delay(500));
        return of(allFavoriteList);
    }

    public getStockExchangeInvestmentById(investmentId: string): Observable<InvestmentItem> { //TODO implement backend
        const findInvestment: InvestmentItem = allInvestmentsStockExchange.find(investment => investment?.id === investmentId);

        return of(findInvestment);
    }

    public getFixedIncomeInvestments(category: string): Observable<Array<InvestmentCategory>> {
        const filterInvestment: Array<InvestmentCategory> = investmentsByCategory?.filter(investment => {
            return investment?.category === category;
        });
        return of(filterInvestment);
    }

    public getFixedIncomeInvestmentById(investmentId: string): Observable<InvestmentCategory> {
        const findIncomeInvestment: InvestmentCategory = investmentsByCategory?.find(investment => {
            return investment?.id === investmentId;
        });
        return of(findIncomeInvestment);
    }

    public getVariableIncomeInvestments(): Observable<Array<IVariableIncomeInvestment>> {
        return of(allVariableIncomeInvestments);
    }

    public getVariableIncomeInvestmentById(investmentId: string): Observable<IVariableIncomeInvestment> {
        const findVariableInvestment: IVariableIncomeInvestment = allVariableIncomeInvestments?.find(investment => {
            return investment?.id === investmentId;
        });
        return of(findVariableInvestment);
    }

    public getUserInvestment(): Observable<Array<IUserInvestment>> {
        return of(allUserInvestments);
    }
}